/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import DefaultInfoCard from "pages/LandingPages/superComponents/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "pages/LandingPages/superComponents/Cards/BlogCards/CenteredBlogCard";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

function Specialties({quoteAction}) {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid container justifyContent="flex-center">
            <Grid item xs={12}>
              <MKTypography align="center" variant="h3" mb={4}>We service a wide range of agencies & locations.</MKTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Events"
                    description={
                      <List dense={false}>
                        <ListItem>
                          <ListItemText primary="Bay Area Weddings" secondary={null}/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Field Trips"/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Private Events"/>
                        </ListItem>
                      </List>
                    }
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Agencies"
                    description={
                      <List dense={false}>
                        <ListItem>
                          <ListItemText primary="The City of San Francisco" secondary={null}/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Marin Headlands Education Program"/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Alcatraz Education"/>
                        </ListItem>
                      </List>
                    }
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Parks"
                    description={
                      <List dense={false}>
                        <ListItem>
                          <ListItemText primary="Golden Gate National Parks Services" secondary={null}/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Parks Conservancy"/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="San Francisco Maritime National Park Association"/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="The National Park Trust"/>
                        </ListItem>
                      </List>
                    }
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Historical Sites"
                    description={
                      <List dense={false}>
                        <ListItem>
                          <ListItemText primary="Fort Point" secondary={null}/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Golden Gate National Recreation Area"/>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Muir Woods"/>
                        </ListItem>
                      </List>
                    }
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Ready to learn more?"
              description="We're standing by to customize your experience"
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "find out more",
                callback: quoteAction,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Specialties.defaultProps = {
  quoteAction: ()=>{},
}

Specialties.propTypes = {
  quoteAction: PropTypes.func
}

export default Specialties;
