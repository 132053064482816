export default {
  ".floating-button-wrapper": {
    backdropFilter: "none !important",
  },
  ".floating-button": {
    position: "fixed !important",
    left: 0,
    zIndex: 999,
  },
  ".floating-button.bottom": {
    bottom: 0,
    width: "calc(100vw - 32px)",
    margin: "0 16px 4px",
  },
  ".floating-button-wrapper.contact-visible .floating-button": {
    display: "none",
  },
};
