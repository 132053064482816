// @mui icons

// Otis Kit PRO components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/dharris.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "D Harris Tours, Inc.",
    image: logoCT,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "Help & Support",
      items: [
        {
          name: "415.902.8542",
          href: "tel:+14159028542",
        },
        {
          name: "juanaharrisdht@att.net",
          href: "mailto:juanaharrisdht@att.net",
        },
      ],
    },
    {
      name: "Reference Numbers",
      items: [
        {
          name: "CA 273437",
        },
        {
          name: "TCP 017270-B",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} DharrisTours
    </MKTypography>

  ),
};
