/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/LandingPages/Landing/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
               We're here for you.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How do I know which bus is right for me?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Let our reservation specialists help you determine which transportation options fit your group’s unique needs. Having booked over 13,135 trips with over 400,000 satisfied passengers, you can always rely on us to find the best travel solution for you.
            </FaqCollapse>
            <FaqCollapse
              title="Are you ADA/SPAB certified?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Yes
            </FaqCollapse>
            <FaqCollapse
              title="I need a bus quickly! How quickly can I make a reservation?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Our staff are available 7 days a week. Please use the request quote form to submit a request. You may also call us
              at <a href="tel:+14159028542">14159028542</a> or email at <a href="mailto:juanaharrisdht@att.net">juanaharrisdht@att.net</a>
            </FaqCollapse>
            <FaqCollapse
              title="How much does a trip cost?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Typically, shuttle buses have a 4 hour minimum and the cost is based on the number of passengers and the duration.
              Additional fuel cost may be incurred if you travel over 25 miles. All of the charges will be listed on the quote you receive.
            </FaqCollapse>
            <FaqCollapse
              title="Do you have long term service?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              If you’re looking for recurring shuttle service for your business, school, college campus, or senior living community, DHarris Tours can help. We can provide you reliable, thoroughly inspected, and fully insured transportation options for any recurring series of trips.
              <br />
              Our dedicated long-term service specialists will work with you to create a plan that meets all of your organization’s needs, whether that means providing for your employees’ commute every day for a year, handling shuttle services for a week-long convention, or picking up your senior living residents for their weekly trip to the grocery store.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
