/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useState } from 'react'

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Landing page sections
import Search from "pages/LandingPages/Landing/sections/Search";
import Buses from "pages/LandingPages/Landing/sections/Buses";
import Testimonials from "pages/LandingPages/Landing/sections/Testimonials";
import Faq from "pages/LandingPages/Landing/sections/Faq";
import Contact from "pages/LandingPages/Landing/sections/Contact";
import SPAB from "pages/LandingPages/Landing/sections/SPAB";
import Specialties from "pages/LandingPages/Landing/sections/Specialties";

// Routes
// import routes from "routes";
import footerRoutes from "footer_simple.routes";

// Images
import bgImage from "assets/images/parents2.jpg";
import {useMediaQuery, useIntersectionObserver} from "@uidotdev/usehooks";
import Anniversary from "./sections/Anniversary";
import busButtonImg from "../../../assets/images/small-logos/bussmol.png";
// import Icon from "@mui/material/Icon";

const getOffsetTop = function (elem) {

  // Set our distance placeholder
  let distance = 0

  // Loop up the DOM
  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop
      elem = elem.offsetParent
    } while (elem)
  }

  // Return our distance
  return distance < 0 ? 0 : distance
}

function Landing() {
  const contactRef = useRef(null)
  const [searchVals, setSearchVals] = useState({startLoc: '', destination: '', date: ''})
  const [showModal, setShowModal] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 767px)")
  const [contactWrapperRef, contactObsrvr] = useIntersectionObserver({ // eslint-disable-line
    root: null,
    threshold: 0,

    rootMargin: "0px",
  })

  const toggleModal = () => setShowModal(!showModal)

  const scrollToContactForm = () => {
    const top = getOffsetTop(contactObsrvr.target) - 30
    window.scrollTo({top , behavior: 'smooth'})
  }

  return (
    <>
      <DefaultNavbar
        routes={[
        ]}
        action={{
          type: "internal",
          route: "/careers",
          label: "Now Hiring",
          color: "info",
          callback: scrollToContactForm
        }}
        sticky
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              D.Harris Tours & Bus Rentals
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Our team does the work so you can Focus on your journey.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="img" src={busButtonImg} onClick={toggleModal}
             sx={{ position: "fixed", bottom: "20px", left: "20px", zIndex: 1, cursor: "pointer" }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.95),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
        className={`${isSmallDevice ? ' floating-button-wrapper ' : ''} ${contactObsrvr?.isIntersecting ? ' contact-visible ' : ''}`}
      >
        <Search scrollToContact={scrollToContactForm} setSearchVals={setSearchVals}/>
        <Anniversary showModal={showModal} setShowModal={setShowModal} />
        <SPAB />
        <Specialties quoteAction={scrollToContactForm} />
        <Testimonials />
        <Buses quoteAction={scrollToContactForm} />
        <MKBox component="section" py={3}>
          <Container>
            <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    color="primary"
                    count={25000}
                    title="Trips"
                    description="... and counting. Keeping our customers satisfied and happy is our pleasure"
                />
              </Grid>
              <Grid item xs={12} md={4} display="flex">
                <DefaultCounterCard
                    color="primary"
                    count={450000}
                    title="Student Moved"
                    description="SPAB certified drivers keep your children safe."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    color="primary"
                    count={24}
                    suffix="/7"
                    title="Support"
                    description="Our dispatchers and response team are always there for you"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    color="primary"
                    count={23}
                    title="Years"
                    description="Proudly serving the community since 1999."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                    color="primary"
                    count={0}
                    suffix=""
                    title="Injuries"
                    description="0 injuries in 23 years of service."
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <div id="faq" name="faq"/>
        <Faq />
        <Contact contactRef={contactRef} contactWrapperRef={contactWrapperRef} searchVals={searchVals}/>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Landing;
