export default {
  host: '/',
  basePath: 'assets/25year/',
  thumbPath: 'assets/25year/thumbnail/',
  thumbSuffix: '.jpg',
  data: [
    { filename: 'WR0Z-0DAG20IRJ0W3TSES', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-0MFVGPUG8Y1XOXPO', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-15I5XXTFAHKHLFOE', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-1F7QZV29P3S016N2', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-1LYIMI773FYSX2GO', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-1OCRLNPNQ7S4W4X6', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-23XAJ3S6B3WYVJKM', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-2OU8GNRKST3KONSU', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-4B58AAFKPOQGTSRJ', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-4K41CQOLIG6LPW37', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-4PZ47QZG7A77NUNF', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-541IL3H10SRR612Q', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-5DP8Y2DFCXCZC4TN', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-5JK4AG5TU898KUKI', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-68EJY2U6EFCRV9D4', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-6MRP13QGAS6XA2JE', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-7PX462BH7XJO6FXR', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-7SRK79H25CVPYTM1', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-8QLPF7VGHA58V4I9', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-904LL593WYMYG6TT', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-9FKSY2YSR28GTEU3', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-9N9D941O5NO63BHW', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-AV83ESQFEP4KWJH8', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-AWNHMWH4CH5QF1UE', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-C37B7M4P968JUDDD', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-CQEYAKPIC90VENN5', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-DBI61HUP1IP79713', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-ERYLBHUDFAKGF8AN', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-FBP02W9944H31H4N', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-FNJQWPY9PILO1K96', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-G697EN62ZI6BG9ZG', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-G9FTH6KA5Q9P1TI3', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-GBWDZEAK1MD96PYY', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-GM1T0GTQV79BK7RH', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-H8U6JFPZSYC0OESZ', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-HDVHU8ENHTSFD7D1', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-I7F4B1SK81XUFWHU', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-IL41KVJIFGU91WBQ', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-J5WX8JCYO6NMKZ3M', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-JLCYIH21BGRH0VQD', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-JT0U9GAFWCJ8SQP4', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-KA8C1MFHHAN3C5SR', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-KMHLRU904H96TFDI', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-KS10RRB6JU69GD6W', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-KZKLQ0911G7KZK1J', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-LEWCRSBHKE9V7BMT', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-LVOY70RJ6PDFO8KW', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-LXXQLGQ3N47QWT3T', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-M79W4VJ7TR6PYRYZ', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-M90AQK0QN886UGS5', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-MAIXRKRP5PQKTGIR', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-MD6OP4YFAZP22K6T', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-MNS26X3ZH9MOE9YT', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-MOZD4HMOK6AGFI0I', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-N3EEGCENUAT8NKAH', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-N3EKPLVDHAV1GI30', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-N6BNA4VRCPZVF4VQ', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-N7PAKCJ19LU4UPP6', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-OSWYHRU184WVS8NI', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-P15WHWSSIB8HACO6', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-P7KX29AIEX2JYSUD', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-PWFPT750GDINU3RV', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-R4SHUETU6JHHWSX9', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-R4UD3L6UI3GYEAO1', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-R4VTD3Y3AB489O3D', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-RJ7VPR5Y3XY6HA6U', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-RQNOTH4GTRH7JS1F', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-T0JIWKJ0HNNLQI2D', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-THSY9W4DS5LZNZGG', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-TQR5IXSL59Q6VO4Q', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-UEZ789SJWDX5F3JK', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-VTAZD8KJETHO5C9R', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-VZTE1NX1FEC99FF0', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-WDU75RQ1XQC9D1CS', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-WQE7PUIPV6TEJ3DP', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-X14D4NWKCDOPW8MT', type: 'video', suffix: '.mp4'},
    { filename: 'WR0Z-X84KHEA0YA2BWXKK', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-YM7IKHR3A5EUVWJX', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-YTZ1UQW9GOMGVNRR', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-Z1AFWNK5SWYQKN9C', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-Z3A6UJ35OY5ZF7SM', type: 'image', suffix: '.jpeg'},
    { filename: 'WR0Z-ZQKDPNL1MDQOQGEX', type: 'image', suffix: '.jpeg'},
  ]
}
