import DayJS from "dayjs";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";

const DEFAULT_RETURN = 'Same as Pickup'
export const formatDate = (dateString) => {
  const date = new DayJS(dateString)

  return (
      <>
        <div>{date.format('MM - DD - YYYY')}</div>
        <div>{date.format('h:mm A')}</div>
      </>
  )
}

export const formatContactDetails = (contact) => {
  const {name, email, telephone} = contact
  return (
      <>
        <div>{name}</div>
        <div><a href={`mailto:${email}`}>{email}</a></div>
        <div><a href={`tel:${telephone}`}>{telephone}</a></div>
      </>
  )
}

export const formatAddressLink = address => {
  if(!address || address === DEFAULT_RETURN) return address

  return (
      <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`} target="_blank"
         rel="noreferrer">
        {address}
      </a>
  )
}

export const formatStatus = isNew => (
      <MKBadge variant="contained"
               badgeContent={isNew ? 'New' : 'Processed'}
               color={isNew ? 'success' : 'secondary'}
               size="xs" container
      />
    )

export const formatDetail = id => (
    <Link to={`/requests/${id}`} target='_blank'>
      <Button variant="contained" color="primary" size="small" sx={{ color: 'white'}}>
        <MKTypography color="white" variant="body2">View</MKTypography>
      </Button>
    </Link>)
