/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState, useRef} from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha';
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import {DayCalendarSkeleton, LocalizationProvider, PickersDayProps} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const DEFAULT_RETURN_PICKUP = 'Same as Pickup'
const ONE_WAY_TRIP_PICKUP = 'One-way trip'
const MAX_RESOURCE = 10


const howAvailable = resourceCount =>
    resourceCount >= MAX_RESOURCE ? 'none'
        : resourceCount <= MAX_RESOURCE / 3 ? 'high'
        : resourceCount <= MAX_RESOURCE / 3 * 2 ? 'medium'
        : 'low'

function DayAvailability(props: PickersDayProps) {
  const { availabilityData = {}, day, outsideCurrentMonth, ...other } = props;
  const dayIndex = day.date()

  if(outsideCurrentMonth || !Object.keys(availabilityData).length ) {
    return (
        <div>
          <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </div>
    )
  }


  const morningSuffix = howAvailable(availabilityData[dayIndex].morningCount)
  const eveningSuffix = howAvailable(availabilityData[dayIndex].eveningCount)

  return (
      <div className={`availability a10y-morning-${morningSuffix} a10y-evening-${eveningSuffix} `}>
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </div>
  );
}

function Contact({id}) {
  const [startLoc, setStartLoc] = useState("")
  const [destination, setDestination] = useState("")
  const [date, setDate] = useState("")
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [telephone, setTelephone] = useState("")
  const [numPassenger, setNumPassenger] = useState("")
  const [calendarDate, setCalendarDate] = useState(dayjs())
  const [isTripDetailOpen, setIsTripDetailOpen] = useState(false)
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [isRoundTrip, setIsRoundTrip] = useState(true)
  const [message, setMessage] = useState("")
  const [createdAt, setCreatedAt] = useState("")
  const [isAda, setIsAda] = useState(false)
  const [isShuttle, setIsShuttle] = useState(false)
  const [isTextEnabled, setIsTextEnabled] = useState(false)
  const [isTripRequest, setIsTripRequest] = useState(false)
  const [requestId, setRequestId] = useState(id)
  const [isExpanded, setIsExpanded] = useState(false)
  const [returnLocation, setReturnLocation] = useState(DEFAULT_RETURN_PICKUP)
  const [lastRoundTripLoc, setLastRoundTripLoc] = useState(DEFAULT_RETURN_PICKUP)
  const [isLoading, setIsLoading] = useState(false)
  const [availabilityData, setAvailabilityData] = useState({})
  const requestAbortController = useRef(null)
  const form = useRef()
  const recaptcha = useRef()
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


  const fetchEvents = (date) => {
    const controller = new AbortController();
    fetch(`https://dharristours.simpsf.com/tools/cal/availability.php?start=${date.format('YYYY-MM-DD')}`,{
      signal: controller.signal
    })
        .then((response) => response.json())
        .then((availabilityDayMap) => {
          setAvailabilityData(availabilityDayMap)
          setIsLoading(false)

        }).catch((error) => {
      // ignore the error if it's caused by `controller.abort`
      if (error.name !== 'AbortError') {
        throw error;
      }
    });



    requestAbortController.current = controller;
  }

  const fetchRequest = async (requestID) => {
    const response = await fetch(`https://dharristours.com/requests.php?id=${requestID}`)
    const respData = await response.json()

    if(respData?.length > 0){
      const {
        pickupLocation, destination, tripdate, returnLocation, isRoundTrip,
        num_passengers, start_time, end_time,
        isAda, isShuttle, isTextEnabled,
        message,
        created_at,
        fname, lname, email, telephone} = respData[0]
      setStartLoc(pickupLocation)
      setDestination(destination)
      setDate(tripdate)
      setFname(fname)
      setLname(lname)
      setEmail(email)
      setIsAda(isAda)
      setIsShuttle(isShuttle)
      setIsTextEnabled(isTextEnabled)
      setMessage(message)
      setCreatedAt(created_at)
      setNumPassenger(num_passengers)
      setTelephone(telephone)
      setDate(tripdate)
      setStartTime(start_time)
      setEndTime(end_time)
      setCalendarDate(dayjs(date))
      setIsRoundTrip(isRoundTrip)
      setReturnLocation(returnLocation)
      setLastRoundTripLoc(returnLocation)
    }
    console.log(respData)
  }
  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setAvailabilityData({});
    fetchEvents(date);
  }

  const handleOnDateChange = newValue => {
    setCalendarDate(newValue)
    setDate(newValue.format('YYYY-MM-DD'))
    setIsExpanded(true)
  }
  const handleFormDateChange = newValue => {
    setCalendarDate(dayjs(newValue))
    setDate(newValue)
  }

  useEffect(() => {
    if (id) {
      setRequestId(id)
      fetchRequest(requestId)
    }
  }, [id])

  useEffect(() => {
    // abort request on unmount
    fetchEvents(dayjs().date(1))
    if(requestId) fetchRequest(requestId)

    return () => requestAbortController.current?.abort();
  }, []);

  useEffect(() => {
    if (isRoundTrip){
      setReturnLocation(lastRoundTripLoc)
    }

    if (!isRoundTrip){
      setLastRoundTripLoc(returnLocation)
      setReturnLocation(ONE_WAY_TRIP_PICKUP)
    }
  }, [isRoundTrip])

  useEffect(() => {
    if(isExpanded) {
      setIsTripDetailOpen(true)
      setIsTripRequest(true)
    }
    if(!isExpanded) {
      setIsTripDetailOpen(false)
      setIsTripRequest(false)
    }
  }, [isExpanded, isTripDetailOpen, isTripRequest])

  const sendEmail = async (e) => {
    e.preventDefault();
    const data = new FormData(form.current)
    console.log(data)

    const templateID = isTripDetailOpen ? 'xx' : 'xx'
    console.log(templateID)
    emailjs.null;
    /* sendForm('service_tejtpek', templateID, form.current, 'skCyXWNpIL2JrIoZY')
        .then((result) => {
          setFormSubmitted(true)
          console.log(result.text);
        }, (error) => {
          setFormSubmitted(true)
          console.log(error.text);
        }); */
    setFormSubmitted(true)
    return false;
  };

  const currDate = () =>  new Date().toLocaleDateString('en-US').replaceAll('/','-')

  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      <MKBox
        component="img"
        alt="lake house"
        src="https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80"
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />
      <Container sx={{ padding: { xs: 0, sm: 1}}}>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12}  justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                overflow: 'hidden',
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2}>
                Request ID #{requestId}
              </MKTypography>
              <MKTypography variant="subtitle1" textAlign="center" mb={2}>
                Created on {createdAt && dayjs(createdAt).format('MMMM DD, YYYY')}
              </MKTypography>
              <MKBox component="form" method="post" autoComplete="off" ref={form} onSubmit={sendEmail} id="contactForm">
                <MKBox py={3} px={{xs: 3, md: 12, lg: 6, xl: 12}}>
                  <Grid container spacing={3} sx={{mb: 3}}>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <input type="hidden" name="dateposted" value={currDate()} />
                      <MKInput
                          variant="standard"
                          placeholder=""
                          label="First Name"
                          name="fname"
                          InputLabelProps={{shrink: true}}
                          value={fname}
                          onChange={e => setFname(e.target.value)}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          placeholder=""
                          label="Last Name"
                          name="lname"
                          InputLabelProps={{shrink: true}}
                          value={lname}
                          onChange={e => setLname(e.target.value)}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          type="email"
                          placeholder="eg. material@design.com"
                          label="Email Address"
                          name="email"
                          InputLabelProps={{shrink: true}}
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          type="tel"
                          placeholder="eg. +1 (555) 555-5555"
                          label="Telephone #"
                          name="telephone"
                          InputLabelProps={{shrink: true}}
                          value={telephone}
                          onChange={e => setTelephone(e.target.value)}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item>
                          <Grid container spacing={3} sx={{mb: 3}}>
                            <Switch id="isTripRequest"
                                    name="isTripRequest"
                                    checked={isTripRequest}
                                    value="Yes"
                                    sx={{display: 'none'}}
                            />
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              <MKInput type="date" variant="standard"
                                       placeholder="Please select date"
                                       onChange={(e) => {console.log(e); handleFormDateChange(e.target.value)}}
                                       value={date}
                                       label="Trip Date"
                                       name="date"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       required={isTripDetailOpen}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              <MKInput
                                  type="number"
                                  variant="standard"
                                  placeholder=""
                                  label="# of Passengers"
                                  name="num_passengers"
                                  InputLabelProps={{shrink: true}}
                                  value={numPassenger}
                                  onChange={e => setNumPassenger(e.target.value)}
                                  fullWidth
                                  required={isTripDetailOpen}
                              />
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                              <MKTypography
                                  component="label"
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  htmlFor="roundCheck"
                                  sx={{userSelect: "none", cursor: "pointer"}}
                              >
                                One Way
                              </MKTypography>
                              <Switch id="roundCheck"
                                      name="isRoundTrip"
                                      checked={isRoundTrip}
                                      onClick={() => setIsRoundTrip(!isRoundTrip)}
                                      value="Yes"
                              />
                              <MKTypography
                                  component="label"
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  htmlFor="roundCheck"
                                  sx={{userSelect: "none", cursor: "pointer"}}
                              >
                                Round Trip
                              </MKTypography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              <Autocomplete
                                  freeSolo
                                  onInputChange={(e, val) => setStartLoc(val)}
                                  options={[]}
                                  inputValue={startLoc}
                                  renderInput={(params) => <MKInput
                                      variant="standard"
                                      {...params}
                                      placeholder=""
                                      label="Pickup Location"
                                      name="pickup"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                      required={isTripDetailOpen}
                                  />}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              <Autocomplete
                                  freeSolo
                                  onInputChange={(e, val) => setDestination(val)}
                                  options={[]}
                                  inputValue={destination}
                                  renderInput={(params) => <MKInput
                                      variant="standard"
                                      {...params}
                                      placeholder=""
                                      label="Destination"
                                      name="destination"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                      required={isTripDetailOpen}
                                  />}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              <Autocomplete
                                  freeSolo
                                  options={["Same as Pickup"]}
                                  readOnly={!isRoundTrip}
                                  value={returnLocation}
                                  renderInput={(params) => <MKInput
                                      variant="standard"
                                      {...params}
                                      label="Return Location"
                                      name="return"
                                      InputLabelProps={{shrink: true}}
                                      onChange={(e) => setReturnLocation(e.target.value)}
                                      fullWidth
                                      required={isTripDetailOpen && isRoundTrip}
                                  />}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6} />
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              {!isSafari &&
                                  <MKInput type="time" variant="standard"
                                       label="Start Time"
                                       name="start_time"
                                       InputLabelProps={{shrink: true}}
                                       value={startTime}
                                       onChange={e => setStartTime(e.target.value)}
                                       fullWidth
                                       required={isTripDetailOpen}
                              />}
                              {isSafari &&
                                  <MKInput type="text" variant="standard"
                                       label="Start Time"
                                       name="start_time"
                                       InputLabelProps={{shrink: true}}
                                       value={startTime}
                                       onChange={e => setStartTime(e.target.value)}
                                       fullWidth
                                       placeholder="12:00 PM"
                                       required={isTripDetailOpen}
                              />}
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} xl={6}>
                              {!isSafari &&
                                  <MKInput type="time" variant="standard"
                                       label="End Time"
                                       name="end_time"
                                       InputLabelProps={{shrink: true}}
                                       value={endTime}
                                       onChange={e => setEndTime(e.target.value)}
                                       fullWidth
                                       required={isTripDetailOpen}
                              />}
                              {isSafari &&
                                  <MKInput type="text" variant="standard"
                                       label="End Time"
                                       name="end_time"
                                       InputLabelProps={{shrink: true}}
                                       value={endTime}
                                       onChange={e => setEndTime(e.target.value)}
                                       fullWidth
                                       placeholder="12:00 PM"
                                       required={isTripDetailOpen}
                              />}
                            </Grid>
                            <Grid item xs={12} sx={{ml: -1}}>
                              <Switch id="adaCheck" name="isAda" value="Yes"
                                      checked={isAda}
                                      onClick={() => setIsAda(!isAda)}/>
                              <MKTypography
                                  component="label"
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  htmlFor="adaCheck"
                                  sx={{userSelect: "none", cursor: "pointer"}}
                              >
                                ADA required?
                              </MKTypography>
                            </Grid>
                            <Grid item xs={12} sx={{ml: -1}}>
                              <Switch id="shuttleCheck" name="isShuttle" value="Yes"
                                      checked={isShuttle}
                                      onClick={() => setIsShuttle(!isShuttle)} />
                              <MKTypography
                                  component="label"
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  htmlFor="shuttleCheck"
                                  sx={{userSelect: "none", cursor: "pointer"}}
                              >
                                Shuttle request?
                              </MKTypography>
                            </Grid>
                            <Grid item xs={12} sx={{ml: -1}}>
                              <Switch id="textCheck" name="isTextEnabled" value="Yes"
                                      checked={isTextEnabled}
                                      onClick={() => setIsTextEnabled(!isTextEnabled)} />
                              <MKTypography
                                  component="label"
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  htmlFor="textCheck"
                                  sx={{userSelect: "none", cursor: "pointer"}}
                              >
                                Text reminders of scheduled trips.
                              </MKTypography>
                            </Grid>
                          </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                          variant="standard"
                          label="Message"
                          name="message"
                          rows={4}
                          InputLabelProps={{shrink: true}}
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          multiline
                          fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      {/* <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                        Send Message
                      </MKButton> */}
                      <ReCAPTCHA
                          ref={recaptcha}
                          size="invisible"
                          sitekey="6LcTvwYiAAAAAJawZp-RkIOjV8pWEg0yZhDwMs4x"
                      />
                    </Grid>
                  </Grid>
                </MKBox>
              </MKBox>
              {isFormSubmitted && <MKTypography variant="body1" textAlign="center" mb={2}>
                Thank you! Great job, buddy!.
              </MKTypography>}
            </MKBox>
          </Grid>
          <Grid item xs={12}  justifyContent="center" flexDirection="column">
            <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                sx={{
                  overflow: 'hidden',
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 6 },
                  py: { xs: 3, sm: 8 },
                  mb: { xs: 0, lg: 8 },
                  mt: { xs: 0, lg: -6 },
                }}
            >
              <MKTypography variant="body1" textAlign="center" mb={2}>
                Bus Availability *
              </MKTypography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    views={['day']}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    value={calendarDate}
                    onChange={handleOnDateChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                      day: DayAvailability,
                    }}
                    slotProps={{
                      day: {
                        availabilityData,
                      },
                    }}
                />
              </LocalizationProvider>

              <MKTypography variant="body2" textAlign="left" mb={2} >
                <div id="availabilityKeyWrapper">
                  <Grid container spacing={{ xs: 0, lg: 0 }}>
                    <Grid item xs={12} lg={5}>
                      <span className="time-key morning" />Daytime
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <span className="time-key evening" />Evening
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <span className="color-key high" /> Available
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <span className="color-key medium" /> Light
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <span className="color-key low" /> Moderate
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <span className="color-key none" /> Heavy
                    </Grid>
                  </Grid>
                </div>
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Contact.propTypes = {
  id: PropTypes.string.isRequired
};

export default Contact;
