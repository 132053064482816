/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Careers page component
import AboutUsOption from "pages/LandingPages/Careers/components/AboutUsOption";
import ZoneImage from "assets/images/BayMapZones.jpg";
import SimpleBackgroundCard from "pages/LandingPages/superComponents/Cards/BackgroundCards/SimpleBackgroundCard";

function SPAB() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <SimpleBackgroundCard
                image={ZoneImage}
                title="Student Pupil Activity Bus (SPAB)"
                description={<>
                  <MKTypography variant="body2" color="light" mb={5}>
                    In order to operate a SPAB Bus, operators must past stringent background checks, training, and medical examinations.
                    All certificates are processed by the California Highway Patrol and inspected yearly.
                  </MKTypography>
                </>}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="settings_overscan"
                content={
                  <>
                    <MKTypography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                      We are approved vendors for many districts including (but not limited to)
                    </MKTypography>
                    <List dense={false}>
                      <ListItem>
                        <ListItemText primary="San Francisco USD" secondary={null}/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Oakland USD"/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="West Contra Costa USD"/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="San Leandro USD"/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="San Rafael USD"/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="... and more"/>
                      </ListItem>
                    </List>
                  </>
                }
              />
              <AboutUsOption
                  icon="mediation"
                  content={
                    <>
                      <MKTypography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        We have a history of safety and excellence.
                      </MKTypography>
                      <MKTypography
                          component="a"
                          href="https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=2016332"
                          target="_blank"
                          rel="noreferrer"
                          variant="p"
                          opacity={0.8}
                      >
                        Click here to View our SAFER records.
                      </MKTypography>
                    </>
                  }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SPAB;
