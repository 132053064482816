/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useState, useRef} from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const ROLE = {
  driver: "Driver",
  office: "Office Staff"
}

function Contact({contactRef}) {
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [hasLiscence, setHasLiscence] = useState(false)
  const [hasCriminal, setHasCriminal] = useState(false)
  const [role, setRole] = useState(ROLE.driver)
  const form = useRef()
  const recaptcha = useRef()


  const sendEmail = async (e) => {
    e.preventDefault();

    await recaptcha.current.executeAsync();
    setFormSubmitted(false)

    const templateID = role === ROLE.driver ? 'template_3522hth' : 'template_yy12gap'

    emailjs.sendForm('service_tejtpek', templateID, form.current, 'skCyXWNpIL2JrIoZY')
        .then((result) => {
          setFormSubmitted(true)
          console.log(result.text);
        }, (error) => {
          setFormSubmitted(true)
          console.log(error.text);
        });

    return false;
  };

  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      <MKBox
        component="img"
        alt="lake house"
        src="https://images.unsplash.com/photo-1537242892475-cf01eb002083?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={10} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                overflow: 'hidden',
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.9),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2} ref={contactRef}>
                D. Harris Tours Application Form
              </MKTypography>
              {!isFormSubmitted &&
              <MKBox component="form" method="post" autoComplete="off" ref={form} onSubmit={sendEmail} id="contactForm">
                <MKBox py={3} px={{xs: 3, md: 12, lg: 6, xl: 12}}>

                  <Grid container spacing={3} sx={{mb: 3}}>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                      <MKTypography variant="h4" textAlign="center" mb={2} ref={contactRef}>
                        Which role are you applying for?
                      </MKTypography>
                      <ToggleButtonGroup
                          color="info"
                          value={role}
                          exclusive
                          onChange={(e, newRole) => {
                            setRole(newRole)
                          }}
                          aria-label="Platform"
                      >
                        <ToggleButton value={ROLE.driver}
                                      sx={{
                                        minWidth: 120,
                                        fontWeight: 'bold'
                                      }}>{ROLE.driver}</ToggleButton>
                        <ToggleButton value={ROLE.office}
                                      sx={{
                                        minWidth: 120,
                                        fontWeight: 'bold'
                                      }}>{ROLE.office}</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          placeholder=""
                          label="First Name"
                          name="fname"
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          placeholder=""
                          label="Last Name"
                          name="lname"
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          type="email"
                          placeholder="eg. material@design.com"
                          label="Email Address"
                          name="email"
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} xl={6}>
                      <MKInput
                          variant="standard"
                          type="tel"
                          placeholder="eg. +1 (555) 555-5555"
                          label="Telephone #"
                          name="telephone"
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                    </Grid>
                    {role === ROLE.office &&
                    <>
                      <Grid item xs={12} md={6} lg={12} xl={6}>
                        <MKInput type="file" variant="standard"
                                 label="Resume"
                                 name="resume"
                                 inputProps={{accept:".pdf,.doc,.docx,.jpg,.jpeg,.png,.txt"}}
                                 InputLabelProps={{shrink: true}}
                                 fullWidth
                                 required
                        />
                      </Grid>
                    </>
                    }
                    {role === ROLE.driver &&
                    <>
                        <Grid item xs={12} md={6} lg={12} xl={6}>
                          <MKInput type="date" variant="standard"
                                   label="Date of Birth"
                                   name="dob"
                                   InputLabelProps={{shrink: true}}
                                   fullWidth
                                   required
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ml: -1}}>
                          <MKTypography
                              component="label"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              htmlFor="hasCDL"
                              sx={{userSelect: "none", cursor: "pointer"}}
                          >
                            Do you have a Commercial Driver's License?
                          </MKTypography>
                          <Switch id="hasCDL" name="hasCDL"
                                  checked={hasLiscence}
                                  onClick={() => setHasLiscence(!hasLiscence)}
                                  value="Yes" />
                        </Grid>
                        {
                          hasLiscence && (
                              <>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <MKInput
                                      variant="standard"
                                      type="text"
                                      placeholder=""
                                      label="License number"
                                      name="cdl_number"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <MKInput
                                      variant="standard"
                                      type="number"
                                      placeholder=""
                                      label="Number of years of experience"
                                      name="years_of_exp"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <FormLabel id="cdlClass">
                                    <MKTypography
                                        component="label"
                                        variant='overline'
                                        color="text"
                                        fontWeight="regular"
                                        sx={{userSelect: "none", cursor: "pointer"}}
                                    >
                                      Class
                                    </MKTypography>
                                  </FormLabel>
                                  <RadioGroup
                                      row
                                      aria-labelledby="cdlClass"
                                      name="cdl_class"
                                  >
                                    <FormControlLabel value="A" control={<Radio />} label="A" />
                                    <FormControlLabel value="B" control={<Radio />} label="B" />
                                    <FormControlLabel value="C" control={<Radio />} label="C" />
                                  </RadioGroup>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <MKInput
                                      variant="standard"
                                      type="text"
                                      placeholder=""
                                      label="State Issued"
                                      name="state_issued"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MKInput
                                      variant="standard"
                                      label="Endorsements / Passenger Endorsements"
                                      name="endorsements"
                                      rows={4}
                                      InputLabelProps={{shrink: true}}
                                      multiline
                                      fullWidth
                                  />
                                </Grid>

                                <Grid item >
                                  <MKTypography
                                      component="label"
                                      variant="button"
                                      color="text"
                                      fontWeight="regular"
                                      htmlFor="application_documents"
                                      sx={{userSelect: "none", cursor: "pointer"}}
                                  >
                                    Please provide a link to a file or website where you will provide the following documents:
                                    <ul>
                                      <li>Photo of License (front and back)</li>
                                      <li>Employment History / Resume</li>
                                      <li>DMV History / H6 Driving Record</li>
                                      <li>Medical Certificate</li>
                                    </ul>
                                    <a href="https://wetransfer.com/" target="_blank" rel="noreferrer">WeTransfer</a> or Google Drive can work if you need a storage provider.
                                    <br />
                                  </MKTypography>
                                  <MKInput
                                      id="application_documents"
                                      variant="standard"
                                      placeholder=""
                                      label="Link to Application Documents"
                                      name="application_documents"
                                      InputLabelProps={{shrink: true}}
                                      fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <MKInput type="date" variant="standard"
                                           placeholder="Please select date"
                                           label="Please enter medical certificate expiration date"
                                           name="medical_certificate_exp_date"
                                           InputLabelProps={{shrink: true}}
                                           fullWidth/>
                                </Grid>
                              </>
                          )
                        }
                        <Grid item xs={12} sx={{ml: -1}}>
                          <Switch id="hasCriminal" name="hasCriminal"
                                  checked={hasCriminal}
                                  onClick={() => setHasCriminal(!hasCriminal)}
                                  value="Yes" />
                          <MKTypography
                              component="label"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              htmlFor="hasCriminal"
                              sx={{userSelect: "none", cursor: "pointer"}}
                          >
                            Do you have any felony convictions?
                          </MKTypography>
                        </Grid>

                        {hasCriminal && <Grid item xs={12}>
                          <MKInput
                              variant="standard"
                              label="Please explain in as much detail as possible."
                              name="felony_explanation"
                              rows={4}
                              InputLabelProps={{shrink: true}}
                              multiline
                              fullWidth
                          />
                        </Grid>}
                        <Grid item xs={12} sx={{ml: -1}}>
                          <Switch name="willDrugTest" value="Yes" />
                          <MKTypography
                              component="label"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              htmlFor="willDrugTest"
                              sx={{userSelect: "none", cursor: "pointer"}}
                          >
                            Will you submit to a drug test?
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ml: -1}}>
                          <Switch name="willFBIcheck" value="Yes" />
                          <MKTypography
                              component="label"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              htmlFor="willFBIcheck"
                              sx={{userSelect: "none", cursor: "pointer"}}
                          >
                            Will you submit to a FBI background check?
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ml: -1}}>
                          <Switch name="willClearingHouse" value="Yes" />
                          <MKTypography
                              component="label"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              htmlFor="willClearingHouse"
                              sx={{userSelect: "none", cursor: "pointer"}}
                          >
                            Will you submit to a Clearinghouse background check request?
                          </MKTypography>
                        </Grid>
                      </>
                    }
                    <Grid item xs={12}>
                      <MKInput
                          variant="standard"
                          label="Is there anything else you'd like to say or include in your application? (optional)"
                          name="message"
                          rows={4}
                          InputLabelProps={{shrink: true}}
                          multiline
                          fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                        Send Message
                      </MKButton>
                      <ReCAPTCHA
                          ref={recaptcha}
                          size="invisible"
                          sitekey="6LcTvwYiAAAAAJawZp-RkIOjV8pWEg0yZhDwMs4x"
                      />
                    </Grid>
                  </Grid>
                </MKBox>
              </MKBox>}
              {isFormSubmitted &&
              <MKTypography variant="body1" textAlign="center" mb={2}>
                Thank you! We will be in touch with you shortly.
              </MKTypography>}
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Contact.propTypes = {
  contactRef: PropTypes.instanceOf(Object).isRequired,
};

export default Contact;
