// Otis Kit PRO examples
import DHTable from 'components/Table'
import DayJS from 'dayjs'

import Paper from '@mui/material/Paper'
// import MKBadge from "components/MKBadge"
import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import Container from "@mui/material/Container";

import Grid from "@mui/material/Grid";
import {formatDetail, formatAddressLink, formatContactDetails, formatDate, formatStatus} from "./utility";
import MKBox from "../../../components/MKBox";
import Login from "../RequestDetail/sections/Login";
import bgImage from "../../../assets/images/parents2.jpg";

function Requests () {
  const [data, setData] = useState([])
  const [cookies] = useCookies(['reqAuth']);
  const [isAuth, setIsAuth] = useState(false)


  const fetchRequests = async () => {
    const response = await fetch('https://dharristours.com/requests.php')
    const respData = await response.json()
    const formattedData = respData.map((request) => {
      const { tripdate, end_time, start_time} = request
      const tripStart =`${tripdate} ${start_time}`
      let tripEnd =`${tripdate} ${end_time}`

      if( DayJS(tripStart).isAfter(DayJS(tripEnd))) tripEnd = DayJS(tripEnd)
          .add(1,'day')
          .format('MM/DD/YYYY HH:mm A')

      return {
        ...request,
        detail: formatDetail(request.id),
        created: formatDate(request.created_at),
        contact: formatContactDetails({
          name: `${request.fname} ${request.lname}`,
          email: request.email,
          telephone: request.telephone,
        }),
        'trip start': formatDate(tripStart),
        'trip end': formatDate(tripEnd),
        pickup: formatAddressLink(request.pickupLocation),
        destination: formatAddressLink(request.destination),
        return: formatAddressLink(request.returnLocation),
        '# PPL': request.num_passengers,
        'round trip': request.isRoundTrip ? 'Yes' : 'No',
        'shuttle': request.isShuttle ? 'Yes' : 'No',
        'ada': request.isAda ? 'Yes' : 'No',
        'should text': request.isTextEnabled ? 'Yes' : 'No',
        status: formatStatus(request.isNew),
      }
    })
    setData(formattedData)
  }

  useEffect(() => {
    if (cookies.reqAuth){
      const date = new DayJS(cookies.reqAuth)

      if(date.isSameOrAfter(new DayJS())){
        console.log('is within range')
      }
      setIsAuth(true)
    }
  }, [cookies])

  useEffect(() => {
    fetchRequests()
  }, [])


  return (
      <>
      {!isAuth && (
      <MKBox
          minHeight="100vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                    rgba(gradients.dark.main, 0.5),
                    rgba(gradients.dark.state, 0.5)
                )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
      >
        <Container>
          <Grid
              container
              item
              xs={12}
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
          >
            <Login />
          </Grid>
        </Container>
      </MKBox>
  )}
  {isAuth && (
      <Paper>
        <DHTable
            columns={[
              { name: "detail", align: "center", width: '80px' },
              { name: "created", align: "right", width: '150px' },
              { name: "contact", align: "left" },
              { name: "trip start", align: "right", width: '150px'  },
              { name: "trip end", align: "left", width: '150px'  },
              { name: "pickup", align: "center" },
              { name: "destination", align: "center" },
              { name: "return", align: "center" },
              { name: "# PPL", align: "center", width: '60px' },
              { name: "round trip", align: "center", width: '60px' },
              { name: "shuttle", align: "center", width: '60px' },
              { name: "ada", align: "center", width: '60px' },
              { name: "should text", align: "center", width: '50px' },
              { name: "message", align: "left" },
              { name: "status", align: "center" },
            ]}
            rows={data}
        />
      </Paper>
  )}
  </>
  )
}

export default Requests