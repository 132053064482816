/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "../../../../components/MKBadge";

function Testimonials() {
  const image =
    "https://images.unsplash.com/photo-1521668576204-57ae3afee860?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80";

  return (
    <MKBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={3}>
            <Grid
                container
                item
                flexDirection="column"
                alignItems="center"
                xs={12}
                lg={6}
                sx={{ textAlign: "center", mx: "auto" }}
            >
              <MKBadge
                  variant="contained"
                  color="secondary"
                  badgeContent="Employee Spotlight"
                  container
                  circular
                  sx={{ mb: 0 }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                <MKBox
                  component="div"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  // mt={-12}
                  sx={{ position: 'relative', paddingTop: "75%"}}
                >
                  <iframe
                      src="https://player.vimeo.com/video/754680876?h=aeb3f0bbe3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                      style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}
                      title="Al Carter Hall of fame (1).mov" />
                  <script src="https://player.vimeo.com/api/player.js" />
                </MKBox>
              </Grid>

              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h3" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                  Albert Carter
                </MKTypography>
                <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2}>
                  Albert "The Big Hitta" Carter!
                </MKTypography>
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  See all products
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </Grid>
              <Grid item xs={1} />
              <Grid
                  item
                  xs={12}
                  md={5}
                  xl={4}
                  sx={{
                    position: "relative",
                    px: { xs: 6, md: 2 },
                    mt: { xs: 3, md: 0 },
                    my: { xs: 0, md: "auto" },
                    color: ({ palette: { white } }) => white.main,
                  }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="light"
                    mb={2}
                    sx={{ fontSize: "1.125rem" }}
                >
                  We wanted to share Albert's video and congratulate him on his induction to the hall of fame. We are all very proud and honored to know and work with him.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  - Jauna Harris
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
