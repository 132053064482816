/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO components
import SimpleBookingCard from "pages/LandingPages/superComponents/Cards/BookingCards/SimpleBookingCard";
import PropTypes from "prop-types";

// Images
import bus28 from "assets/images/bus/2802 Outside.jpg";
import bus36 from "assets/images/bus/3601 ADA.jpg";
import bus44 from "assets/images/bus/4402 Outside.jpg";
import bus56 from "assets/images/bus/56bus.jpg";
import MKTypography from "../../../../components/MKTypography";

function Buses({quoteAction}) {
  const actionProps = {
    type: "internal",
    route: "/",
    color: "info",
    callback: quoteAction,
    label: "Get Free Quote",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
            container
            justifyContent="center"
            sx={{ pt: 3, pb: 3, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={9} sx={{ textAlign: "center" }}>
            <MKTypography variant="body2" color="black" fontWeight="light" >
              Our most popular buses.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={bus28}
                title="28-Passenger Bus"
                description="The StarTrans Senator II MVP is perfect for your VIP tour or wedding shuttle."
                categories={["VIP", 'Small Groups']}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={bus36}
                title="36-Passenger Bus"
                description="Our fleet has several certified ADA buses and drivers."
                categories={["ADA", 'Luxury']}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={bus44}
                title="44-Passenger Bus"
                description="The XLT is the perfect solution for tours, seniors, churches and school sports teams requiring  higher passenger and luggage capacities."
                categories={["Large Groups", 'Great Deal']}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={bus56}
                title="56-Passenger Bus"
                description="The Volvo 9700 is great for large groups looking for a comfortable ride and a generous luggage capacity."
                categories={["Large Groups", 'Great Deal']}
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Buses.defaultProps = {
  quoteAction: ()=>{},
}

Buses.propTypes = {
  quoteAction: PropTypes.func
}

export default Buses;
