/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 6 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="insights"
                title="Learn As You Go"
                description={(
                    <>
                    We offer on-the-job training and help with the certifications you need to be successful.
                    </>)}
              />
              <SimpleInfoCard
                icon="payment"
                title="SPAB training"
                description="Now available with a dedicated trainer at $75/h with group discounts available."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="access_alarms"
                title="Flexible Hours"
                description="We work together to keep a healthy work/life balance"
              />
              <SimpleInfoCard
                icon="sentiment_satisfied"
                title="Community"
                description="We have a strong company culture that we'd love you to be part of."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
