// Otis Kit PRO examples
// import MKBadge from "components/MKBadge"
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DayJS from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {useCookies} from "react-cookie";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import MKBox from "../../../components/MKBox";
import Login from "./sections/Login"
import Contact from "./sections/Contact";

import bgImage from "../../../assets/images/parents2.jpg";



DayJS.extend(isSameOrAfter)
DayJS.extend(isSameOrBefore)

function RequestDetail () {
  const [cookies] = useCookies(['reqAuth']);
  const [isAuth, setIsAuth] = useState(false)

  const {request_id} = useParams()

  useEffect(() => {
    if (cookies.reqAuth){
      const date = new DayJS(cookies.reqAuth)

      if(date.isSameOrAfter(new DayJS())){
        console.log('is within range')
      }
      setIsAuth(true)
    }
  }, [cookies])

  return (
      <MKBox
          minHeight="100vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                    rgba(gradients.dark.main, 0.5),
                    rgba(gradients.dark.state, 0.5)
                )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
      >
        <Container>
          {!isAuth && (
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <Login />
              </Grid>
          )}
          {isAuth && (
              <Grid container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                  <Contact id={request_id}/>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    py={8}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                  <iframe title="jobs" width="100%" height="100%" src="https://dharristours.simpsf.com/tools/cal/" />
                </Grid>
              </Grid>
        )}
        </Container>
      </MKBox>
  )
}

export default RequestDetail