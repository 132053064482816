/* eslint-disable import/no-unresolved */
/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import mediaData from "assets/data/anniversaryMedia"
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import bgPattern from "../../../../assets/images/shapes/pattern-lines.svg";
// import MKButton from "../../../../components/MKButton";

function Anniversary({showModal, setShowModal}) {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation, Thumbs]);

  const [show, setShow] = useState(false);


  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",
    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  const mediaStyles = {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
  const slideStyles = {
    ".swiper": {
      zIndex: 11,
    },
    ".swiper-wrapper": {
      alignItems: "center"
    },
    ".swiper-button-prev": {
      display: "none",
    },
    ".swiper-button-next": {
      display: "none",
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const toggleModal = () => {
    setShow(!show)
    if(show && thumbsSwiper) thumbsSwiper.update()
    if(!show) setThumbsSwiper(null)
  }

  useEffect(() => {
    if(show !== showModal) toggleModal()
  }, [showModal])

  return (
      <MKBox component="section" py={6}>
        <Container>
      <Modal open={show} onClose={() => setShowModal(false)} sx={{ display: "grid", placeItems: "center" }}>

          <MKBox py={1}
           position="relative"
           bgColor="light"
           variant="gradient"
                 width="90vw"
                 height="90vh"
                 display="flex"
                 flexDirection="column"
                 borderRadius="xl"
                 shadow="xl"
           sx={{
             maxWidth: "800px",
             ...slideStyles,
           }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{zIndex: 4}}>
              <MKTypography variant="h5">
                D. Harris Tours is celebrating <em>25 years</em> in service! <sm>(est. August, 1999)</sm>
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox
                component="img"
                src={bgPattern}
                alt="background-pattern"
                position="absolute"
                top={0}
                left={0}
                width="100%"
                opacity={0.2}
                display={{ xs: "none", md: "block" }}
                sx={{
                  zIndex: 1,
                }}
            />
            <Grid
                container
                justifyContent="center"
                sx={{ pt: 1, pb: 1, position: "relative", zIndex: 3, flexGrow: 1, overflow: "hidden" }}
            >
              <Grid item xs={12} sx={{maxHeight: "100%"}}>
                <Swiper
                    onInit={({ params, navigation }) => {
                      const { navigation: nav } = params;

                      nav.prevEl = navigationPrevRef.current;
                      nav.nextEl = navigationNextRef.current;
                      navigation.init();
                      navigation.update();
                    }}
                    autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
                    speed={800}
                    slidesPerView={1}
                    centeredSlides
                    loop
                    spaceBetween={10}
                    navigation
                    thumbs={{ swiper: thumbsSwiper }}
                >
                  {mediaData.data.map((data) => (
                      <SwiperSlide key={`anniv${data.filename}`}>
                        <Container sx={{height: "calc(90vh - 144px - 64px - 64px)",  marginBottom: "30px", overflow: "hidden"}}>
                          {data.type === 'image'
                              ? ( <MKBox
                                  component="img"
                                  src={`${mediaData.host}${mediaData.basePath}${data.filename}${data.suffix}`} alt="Anniversary Celebration"
                                  sx={{ ...mediaStyles }}/>)
                              : ( <MKBox
                                  component="video"
                                  src={`${mediaData.host}${mediaData.basePath}${data.filename}${data.suffix}`}
                                  controls autoPlay muted
                                  sx={{ ...mediaStyles }}/>)
                          }
                        </Container>
                      </SwiperSlide>
                  ))}
                  <MKTypography
                      variant="h2"
                      color="light"
                      sx={{
                        ...navigationStyles,
                        left: 0,
                      }}
                      ref={navigationPrevRef}
                  >
                    <Icon>chevron_left</Icon>
                  </MKTypography>
                  <MKTypography
                      variant="h2"
                      color="light"
                      sx={{
                        ...navigationStyles,
                        right: 0,
                      }}
                      ref={navigationNextRef}
                  >
                    <Icon>chevron_right</Icon>
                  </MKTypography>
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop
                    spaceBetween={10}
                    slidesPerView={6}
                    freeMode
                    watchSlidesProgress
                    modules={[FreeMode, Navigation, Thumbs]}
                >
                  {mediaData.data.map((data) => (
                      <SwiperSlide  key={`annivThumb${data.filename}`}>
                        <Box sx={{ width: '108px', height: '144px'}}>
                          <Box
                              component="img"
                              src={`${mediaData.host}${mediaData.thumbPath}${data.filename}${mediaData.thumbSuffix}`}
                              alt="Anniversary Celebration"
                              sx={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                        </Box>
                      </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
            </Grid>
          </MKBox>
      </Modal>
        </Container>
      </MKBox>
  );
}

Anniversary.defaultProps = {
  showModal: false,
  setShowModal: () => {},
}

Anniversary.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
}

export default Anniversary;
