/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from 'react'
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import {useIntersectionObserver, useMediaQuery, useWindowScroll} from "@uidotdev/usehooks";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import PropTypes from "prop-types";

function Search({scrollToContact, setSearchVals}) {
  const [startLoc, setStartLoc] = useState("")
  const [destination, setDestination] = useState("")
  const [date, setDate] = useState("")
  const [positions, scrollTo] = useWindowScroll() // eslint-disable-line
  const isSmallDevice = useMediaQuery("only screen and (max-width : 767px)")

  const [searchRef, searchObsrvr] = useIntersectionObserver({ // eslint-disable-line
    root: null,
    threshold: 0,
    rootMargin: "0px",
  })

  const handleSearch = () => {
    setSearchVals({startLoc, destination, date})
    scrollToContact()
  }

  return (
    <MKBox component="section" ref={searchRef}>
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Leave From
            </MKTypography>
            <Autocomplete
              freeSolo
              onInputChange={(e, val) => setStartLoc(val)}
              options={[]}
              renderInput={(params) => <MKInput {...params} variant="standard" placeholder="Enter Starting Location" />}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              To
            </MKTypography>
            <Autocomplete
                freeSolo
                onInputChange={(e, val) => setDestination(val)}
                options={[]}
              renderInput={(params) => <MKInput {...params} variant="standard" placeholder="Enter Destination" />}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Depart
            </MKTypography>
            <MKInput type="date" variant="standard" placeholder="Please select date" fullWidth onChange={(e) => {setDate(e.target.value)}}/>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
            <MKButton variant="gradient" color="info" fullWidth onClick={handleSearch}
                      className={
                        isSmallDevice && !searchObsrvr?.isIntersecting
                          ? "floating-button bottom"
                          : ""}
            >
              Check Availability
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Search.propTypes = {
  scrollToContact: PropTypes.func.isRequired,
  setSearchVals: PropTypes.func.isRequired,
};

export default Search;
