/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Otis Kit PRO components
// import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import HorizontalTeamCard from "../../../../examples/Cards/TeamCards/HorizontalTeamCard";
import willHPic from "../../../../assets/images/willh2.jpg";
// import team2 from "../../../../assets/images/bruce-mars.jpg";
// import team3 from "../../../../assets/images/ivana-squares.jpg";
import juanHPic from "../../../../assets/images/juanh.jpg";

function People() {

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Meet the Team"
            container
            circular
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            The D. Harris Family
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                  image={juanHPic}
                  name="Juana Harris"
                  position={{ color: "info", label: "Owner" }}
                  description="Juana Harris started the business in 1999."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                  image={willHPic}
                  name="William Harris"
                  position={{ color: "info", label: "President" }}
                  description="William Harris started the business in 1999."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default People;
