/* eslint-disable import/no-unresolved */
/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Otis Kit PRO examples
import ComplexReviewCard from "pages/LandingPages/superComponents/Cards/ReviewCards/ComplexReviewCard";

// Images
import review1 from "assets/images/testimonial/testimonial1.jpg";
import review2 from "assets/images/testimonial/testimonial2.jpg";
import logoEBIA from "assets/images/logos/ebia_logo.png";
import logoParks from "assets/images/logos/parkslogo.png";
import bgPattern from "../../../../assets/images/shapes/pattern-lines.svg";
import MKBadge from "../../../../components/MKBadge";


function Testimonials() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",
    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  const slideStyles = {
    ".swiper": {
      zIndex: 11,
    },
    ".swiper-wrapper": {
      alignItems: "center"
    }
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <MKBox py={8}
           position="relative"
           bgColor="dark"
           variant="gradient"
           sx={{
             ...slideStyles,
           }}
    >
      <MKBox
          component="img"
          src={bgPattern}
          alt="background-pattern"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          opacity={0.2}
          display={{ xs: "none", md: "block" }}
          sx={{
            zIndex: 1,
          }}
      />
      <Grid
          container
          justifyContent="center"
          sx={{ pt: 1, pb: 1, position: "relative", zIndex: 3 }}
      >
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <MKBadge
              badgeContent="testimonials"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 4 }}
          />
        </Grid>
      </Grid>
      <Swiper
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;

          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
        speed={800}
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides
      loop
      >
        <SwiperSlide>
          <Container>
            <ComplexReviewCard
              image={review1}
              color="light"
              title="Thank you!!!"
              review="Dear Patrick and Sa,
A huge thanks from all of us at EBIA to you and your team for making our trips possible!  We had so many changes and you came through every time.  The smiles and laughter, excitement to be with one another and in respective venues.  We are your biggest fans!

Thank you again!"
              author={{
                name: "Michelle C.",
                logo: logoEBIA,
                role: "East Bay Innovation Academy",
              }}
            />
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <Container>
            <ComplexReviewCard
              image={review2}
              color="light"
              title="THANK YOU D HARRIS!!!!!!"
              review="Hola D Harris Team,

Thank you very much for all your hard work and flexibility with the Migratory Story Program this year. We know you have many reservations packed schedule each year. We appreciated how accommodating you folks were with the bus changes due to the North Bay fires and last minute bus logistics on our end. It is always a pleasure to work with you folks. We know when we pick up the phone to call D Harris we are insuring reliable transportation services with friendly professionalism.

This year Migratory Story was a fifth grade program that drew parallels between raptor migration and human immigration. During the field visit, with the buses provided by D Harris, the students learned about obstacles and aids that migrating raptors encounter in the natural environment of the national parks. This year we delivered programs in Spanish, Mandarin, and English to be accessible for the all our students. The program completed in a student reflection of obstacles and aids that humans encounter when moving to a new place. The students then painted their interpretation of how to make newcomers feel welcome and safe on their self-written poems about migration. D Harris directly contributed to students sharing their own immigration stories and developing a personal connection with their National Park.

THANK YOU from 196 students, 9 teachers, and 20 staff members!!!! Mags, Erick and myself send our extra thanks to you folks as well.
We took a couple photos to share our gratitude with you all. The second photo is us posing with sharp talons as raptors.

Happy Holidays!!!
"
              author={{
                name: "Kelsi J.",
                logo: logoParks,
                role: "East Bay Innovation Academy",
              }}
            />
          </Container>
        </SwiperSlide>
        <MKTypography
          variant="h2"
          color="light"
          sx={{
            ...navigationStyles,
            left: 0,
          }}
          ref={navigationPrevRef}
        >
          <Icon>chevron_left</Icon>
        </MKTypography>
        <MKTypography
          variant="h2"
          color="light"
          sx={{
            ...navigationStyles,
            right: 0,
          }}
          ref={navigationNextRef}
        >
          <Icon>chevron_right</Icon>
        </MKTypography>
      </Swiper>
    </MKBox>
  );
}

export default Testimonials;
