/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Kit PRO base styles
import colors from "assets/theme/base/colors";

// Otis Kit PRO helper functions

const { availability, white, black } = colors

export default {
  ".availability": {
    backgroundColor: 'transparent',
    position: 'relative',

    "&:before": {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      backgroundImage: '-webkit-linear-gradient(135deg, transparent 50%, transparent 50%)',
    },

    "&.a10y-morning-": {
      "&none": {
        backgroundColor: availability.none,
      },
      "&low": {
        backgroundColor: availability.low,
      },
      "&medium": {
        backgroundColor: availability.medium,
      },
      "&high": {
        backgroundColor: availability.high,
      },
    },

    "&.a10y-evening-": {
      "&none:before": {
        backgroundImage: `-webkit-linear-gradient(135deg, ${availability.none} 50%, transparent 50%)`,
      },
      "&low:before": {
        backgroundImage: `-webkit-linear-gradient(135deg, ${availability.low} 50%, transparent 50%)`,
      },
      "&medium:before": {
        backgroundImage: `-webkit-linear-gradient(135deg, ${availability.medium} 50%, transparent 50%)`,
      },
      "&high:before": {
        backgroundImage: `-webkit-linear-gradient(135deg, ${availability.high} 50%, transparent 50%)`,
      },
    },

    '.MuiButtonBase-root.Mui-selected': {
      backgroundColor: availability.selected,
      color: black.main,
      border: `2px solid ${white.main}`,
      fontFamily: 'bold',

      '&:hover': {
        backgroundColor: availability.hover,
      },
      '&:focus': {
        backgroundColor: availability.focus,
        color: 'white',
      }
    },
  },

  "#availabilityKeyWrapper": {
    width: "320px",
    margin: "0 auto",

    ".time-key": {
      display: "inline-block",
      width: "12px",
      height: "12px",
      marginRight: "3px",

      "&.morning": {
        backgroundImage: `-webkit-linear-gradient(135deg, transparent 50%, grey 50%)`,
      },
      "&.evening": {
        backgroundImage: `-webkit-linear-gradient(135deg, grey 50%, transparent 50%)`,
      },
    },
    ".color-key": {
      display: "inline-block",
      width: "12px",
      height: "12px",
      marginRight: "3px",

      "&.none": {
        backgroundColor: availability.none,
      },
      "&.low": {
        backgroundColor: availability.low,
      },
      "&.medium": {
        backgroundColor: availability.medium,

      },
      "&.high": {
        backgroundColor: availability.high,
      },
    }
  },

};
