/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef} from 'react'

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DayJS from 'dayjs'
import {useCookies} from "react-cookie";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "../../../../components/MKInput";
import MKButton from "../../../../components/MKButton";

// import MKInput from "components/MKInput";


function Login() {
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['reqAuth']);
  const requestAbortController = useRef(null)
  const form = useRef()

console.log(setCookie)
  const sendEmail = async (e) => {
    e.preventDefault();
    const data = new FormData(form.current)
  console.log(data)
    const controller = new AbortController();

    fetch(`https://dharristours.simpsf.com/login.php`,{
      signal: controller.signal,
      method: 'POST',
      body: data,
    })
        .then((response) => response.text())
        .then((text) => {
          if(!text.includes('Log in failed. Invalid username and/or password.')) {
            const date = new DayJS()
            setCookie('reqAuth', date.format('YYYY-MM-DD'), { path: '/', expires: date.add(7, 'day').toDate() })
          } else {
            alert('Invalid Login')
          }
          setFormSubmitted(true)
        }).catch((error) => {
      // ignore the error if it's caused by `controller.abort`
      if (error.name !== 'AbortError') {
        throw error;
      }
    });

    requestAbortController.current = controller;
    return false;
  };

  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      <MKBox
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />
      <Container sx={{ padding: { xs: 0, sm: 1}}}>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                overflow: 'hidden',
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2} >
                Login
              </MKTypography>
              <MKBox component="form" method="post" autoComplete="off" ref={form} onSubmit={sendEmail} id="loginForm">
                    <div id="appLogo"><img id="logoImage" src="https://dharristours.simpsf.com/clients/dharristours/img/logo12.png" alt="dharris logo" border="0" /></div>
                    <h1 className="appTitle">DHarris Tours</h1>
                    <Grid container spacing={3} sx={{mb: 3}}>
                      <Grid item xs={12} >
                        <MKInput
                          variant="standard"
                          type="text"
                          placeholder=""
                          label="Login"
                          name="email"
                          size={30}
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                      </Grid>
                      <Grid item xs={12} >
                        <MKInput
                          variant="standard"
                          type="password"
                          placeholder=""
                          label="Password"
                          name="password"
                          size={30}
                          InputLabelProps={{shrink: true}}
                          fullWidth
                          required
                      />
                      </Grid>
                      <Grid item xs={12} >
                        <input name="remember" type="checkbox" checked="checked" value="true" /> Remember me for 1 week
                      </Grid>
                      <Grid item xs={12} >
                        <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                          Submit
                        </MKButton>
                      </Grid>
                    </Grid>
                  <input type="hidden" name="submitted" value="true" />
                  {/* <input type="hidden" name="url" value="/apps/index.php" /> */}
              </MKBox>
              {isFormSubmitted && <MKTypography variant="body1" textAlign="center" mb={2}>
                Thank you! Great job, buddy!.
              </MKTypography>}
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
// Log in failed. Invalid username and/or password.
Login.propTypes = {
};
export default Login;
